import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';
import ContactBar from 'components/contact/ContactBar';

const styles = theme => ({
  hero: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: '80px',
    marginBottom: '20px',
    [theme.breakpoints.up('lg')]: {
      width: '100px',
    },
  },
  heroTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
});

export const OverlayStyled = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${props => `${props.color}`};
  opacity: ${props => `${props.opacity}`};
  position: absolute;
  z-index: -1;
`;

function ImageHero(props) {
  const {
    data,
    title,
    contactbar,
    brochure,
    logo,
    email,
    telephone,
    opening_hours,
    additional_info,
    external_link,
    bannerImageOverlayColor,
    bannerImageOverlayOpacity,
    classes,
  } = props;
  return (
    <BackgroundImage
      Tag="section"
      id="hero-large"
      fluid={data.childImageSharp.fluid}
      className={classes.hero}
    >
      {bannerImageOverlayColor && bannerImageOverlayOpacity && (
        <OverlayStyled
          color={bannerImageOverlayColor}
          opacity={bannerImageOverlayOpacity}
        />
      )}
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        width="100%"
      >
        <Box component={Grid} container display="flex" justifyContent="center">
          <Box component={Grid} item xs={11} md={8}>
            <Typography
              component="h1"
              variant="h1"
              color="secondary"
              align="center"
              className={classes.heroTitle}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
      {contactbar && (
        <ContactBar
          title={title}
          brochure={brochure}
          email={email}
          telephone={telephone}
          opening_hours={opening_hours}
          additional_info={additional_info}
          external_link={external_link}
        />
      )}
    </BackgroundImage>
  );
}

ImageHero.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  contactbar: PropTypes.bool,
  brochure: PropTypes.string,
  logo: PropTypes.bool,
  email: PropTypes.string,
  telephone: PropTypes.string,
  opening_hours: PropTypes.string,
  additional_info: PropTypes.string,
  external_link: PropTypes.string,
  bannerImageOverlayColor: PropTypes.string,
  bannerImageOverlayOpacity: PropTypes.number,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageHero);

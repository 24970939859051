import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Layout from 'components/layout';
import ImageHero from 'components/hero/imagehero';
import Spacer from 'components/spacer';
import Logo from 'images/LivingSpace-Wordmark.svg';
import { withStyles } from '@material-ui/styles';
import { RichText } from 'prismic-reactjs';
import { Helmet } from 'react-helmet';
import { ImageSlider, Location, Video, Image } from 'components/slices';

const styles = theme => ({
  logo: {
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      width: '100px',
    },
  },
});

function Project(props) {
  const { classes } = props;
  const doc = props.data.prismic.allProjects.edges.slice(0, 1).pop();
  if (!doc) return null;
  return (
    <Layout
      headerMenuType={
        doc.node.header_menu_type ? doc.node.header_menu_type : false
      }
    >
      {doc.node.metadata && doc.node.metadata[0] && (
        <Helmet>
          {doc.node.metadata[0].title && doc.node.metadata[0].title[0] && (
            <title>{doc.node.metadata[0].title[0].text}</title>
          )}
          {doc.node.metadata[0].description &&
            doc.node.metadata[0].description[0] && (
              <Helmet>
                <meta
                  name="description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  property="og:description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  name="twitter:description"
                  content={doc.node.metadata[0].description[0].text}
                />
              </Helmet>
            )}
          {doc.node.metadata[0].keywords &&
            doc.node.metadata[0].keywords[0] && (
              <meta
                name="keywords"
                content={doc.node.metadata[0].keywords[0].text}
              />
            )}
        </Helmet>
      )}
      <ImageHero
        data={doc.node.banner_imageSharp}
        title={doc.node.title[0].text}
      />
      <Container maxWidth="xl">
        <Spacer>
          <Grid container>
            <Box
              component={Grid}
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              mb={[4, 4, 0]}
            >
              <Logo className={classes.logo} />
              <Box py={3}>
                <Typography component="h2" variant="h2" align="center">
                  {doc.node.title[0].text}
                </Typography>
              </Box>
              <Typography component="p" variant="subtitle1">
                {doc.node.address
                  ? doc.node.address[0].address_line_1
                    ? `${doc.node.address[0].address_line_1[0].text},`
                    : null
                  : null}{' '}
                {doc.node.address
                  ? doc.node.address[0].address_line_2
                    ? doc.node.address[0].address_line_2[0].text
                    : null
                  : null}
              </Typography>
              <Typography component="p" variant="subtitle1">
                {doc.node.address[0].town[0].text},{' '}
                {doc.node.address[0].county[0].text},{' '}
                {doc.node.address[0].postcode[0].text}
              </Typography>
            </Box>
            <Box component={Grid} item xs={12} md={5}>
              <Typography component="div" variant="subtitle1" gutterBottom>
                <RichText render={doc.node.introduction} />
              </Typography>
            </Box>
          </Grid>
        </Spacer>
        {doc.node.main_page_slideshow && doc.node.main_page_slideshow[0] && (
          <Spacer>
            {doc.node.main_page_slideshow.length.length > 1 ? (
              <ImageSlider data={doc.node.main_page_slideshow} />
            ) : (
              <Image data={doc.node.main_page_slideshow[0]} />
            )}
          </Spacer>
        )}
        {doc.node.video_url && doc.node.video_url.embed_url && (
          <Spacer>
            <Video data={doc.node} />
          </Spacer>
        )}
        <Spacer>
          <Location
            title={doc.node.title[0].text}
            data={doc.node.address[0]}
            geolocation={doc.node.geolocation}
            locationPostcode={doc.node.address[0].postcode[0].text}
          />
        </Spacer>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query ProjectTypePageQuery($uid: String) {
    prismic {
      allProjects(uid: $uid) {
        edges {
          node {
            metadata {
              description
              keywords
              title
            }
            header_menu_type
            title
            banner_image
            banner_imageSharp {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            introduction
            address {
              address_line_1
              address_line_2
              county
              postcode
              town
            }
            geolocation
            main_page_slideshow {
              title
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 3000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            video_url
          }
        }
      }
    }
  }
`;

Project.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Project);
